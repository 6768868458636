<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
        <b-row class="mt-1">
          <b-col cols="12" md="6" lg="6">
            <b-row>
              <b-col cols="12">
                <validation-provider #default="validationContext" name="Name" rules="required">
                  <b-form-group label="Name">
                    <b-form-input trim placeholder="Name" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider #default="validationContext" name="LastName" rules="required">
                  <b-form-group label="LastName">
                    <b-form-input trim placeholder="LastName" v-model="dataInfo.lastname" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider #default="validationContext" name="E-Mail" rules="required|email">
                  <b-form-group label="E-Mail">
                    <b-form-input trim placeholder="E-Mail" v-model="dataInfo.email" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" v-if="!$router.currentRoute.params.id > 0">
                <validation-provider #default="validationContext" name="Password" rules="required">
                  <b-form-group label="Password">
                    <b-form-input type="password" trim placeholder="Password" v-model="dataInfo.password" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider #default="validationContext" name="Phone">
                  <b-form-group label="Phone">
                    <b-form-input trim placeholder="Phone" v-model="dataInfo.phone" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Role" rules="required">
                  <b-form-group label="Role" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.user_role_id" :options="userRolesOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider #default="validationContext" name="Status" rules="required">
                  <b-form-group label="Status" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.status" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <div class="mt-5 d-flex justify-content-center align-content-end">
              <b-avatar
                  ref="previewEl"
                  :src="(dataInfo.profilePictureBase64 ? dataInfo.profilePictureBase64 : getApiFile(dataInfo.image_url))"
                  :text="avatarText(dataInfo.name + ' ' + dataInfo.lastName)"
                  button
                  variant="primary"
                  size="70px"
                  @click="$refs.refInputEl.click()"/>
              <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @input="inputImageRenderer">
            </div>
          </b-col>
        </b-row>
        <action-buttons :back-route="'system-user-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAvatar,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required, email} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/system/user/store"
import roleModule from "@/views/system/role/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import {useInputImageRenderer} from "@core/comp-functions/forms/form-utils"

export default {
  components: {
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    vSelect,
    ActionButtons,
    Overlay,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_ROLE_MODULE_NAME = 'store-role'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_ROLE_MODULE_NAME, roleModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_ROLE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({
      id: 0,
      name: '',
      lastname: '',
      email: '',
      password: '',
      phone: '',
      title: '',
      image_url: '',
      profilePictureBase64: null,
      user_role_id: null,
      status: 1,
    })

    const userRolesOptions = ref([])

    const previewEl = ref(null)
    const refInputEl = ref(null)
    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      dataInfo.value.profilePictureBase64 = base64
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'system-user-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id > 0 ? router.currentRoute.params.id : 0}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'system-user-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    busy.value = true
    store.dispatch('store-role/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.status === 1) {
          userRolesOptions.value.push({label: value.name, value: value.id})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'system-user-list'})
      }
    }).finally(message => {
      busy.value = false
    })

    return {
      busy,
      dataInfo,
      previewEl,
      refInputEl,
      refFormObserver,
      userRolesOptions,

      statusOptions,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
